import {PageLayout} from "@components";
import {PartnersV2} from "../../landingpagev2/_components/_Partners/_Partners";
import {JoinInstitutionsV2} from "../../landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import React from "react";
import FeatureCard, {FeatureCardLayout, FeatureCardProps} from "@components/FeatureCard/FeatureCard";
import {StaticImage} from "gatsby-plugin-image";
import * as styles from "../../products/orders/orders.module.scss";
import {Demo} from ".././biopharmaceutical";
import Margins from "@components/Margins/Margins";

export default function Services () {

    const demoProps = {
        title: "Enhance contract service workflows, collaborate with confidence",
        subtitle: "Boost efficiency and optimize outcomes with modern lab automation solutions.",
        srcImg: "research-center-of-the-ecole-poly-technique.png",
        disableBackgroundShapes: true,
    }

    const cards: FeatureCardProps[] = [
        {
            title: "Optimize contract service management",
            list: [
                "Revolutionize contract service offerings, setting new benchmarks in service quality and efficiency.",
                "Genemod offers effective management of contract service resources, ensuring cost-effectiveness while delivering top-notch services.",
                "Leverage Genemod's tools for efficient budgeting in contract services, optimizing expenses for high-quality, affordable service delivery.",
            ],
            image: (
                <StaticImage
                    src={"../../../pages/products/lab-protocol/_assets/protocols_image_2.png"}
                    alt="Easily Manage and Submit Orders"
                />
            ),
        },
        {
            title: "Genemod delivers precision, compliance, and security, to enhance laboratory contract services.",
            list: [
                "Genemod's LIMS system centralizes laboratory data management, covering aspects such as research data handling, sample tracking, test result documentation, quality control, and data processing.",
                "Efficiently store, organize, and document research data and reports. Facilitate team collaboration on a centralized platform, minimizing time spent on redundant processes.",
                "Genemod integrates ChatGPT in our electronic lab notebook, enabling report revisions at a click and providing instant information through our Genemod AI chat bot.",
            ],
            image: (
                <StaticImage
                    src={"../../../assets/products/single-source-of-truth.png"}
                    alt="Single Source of truth "
                />
            ),
            orientation: "right",
        },
    ];

    const footerContent = {
        headerText: "Powered by Genemod",
        subheaderText: "Our Enterprise plans help companies ensure data integrity and improve collaboration institution-wide. Each subscription is acustom-built, expert-driven foundation of your lab automation program.",
    }


    return (
        <PageLayout
            seoOptions={{ type: "PREDEFINED", pageName: "services" }}
        >
            <Margins>
            <Demo {...demoProps}/>
            <FeatureCardLayout className={styles.cards}>
                {cards.map((card, index) => {
                    return <FeatureCard {...card} key={index} />;
                })}
            </FeatureCardLayout>
            <PartnersV2 includeHighPerformer />
            <JoinInstitutionsV2 {...footerContent} />
            </Margins>
        </PageLayout>
    );
}